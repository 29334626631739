@import '../../../styles/_sizes.scss';

.gift {
    padding: 0;
    padding-left: 6pt;
    border: 2pt solid;
    border-left: 6pt solid #520a31;
    border-color: #520a31;
    border-radius: 5pt;
    background: #c90e59;
    color: white;
    margin-bottom: 6pt;
}

.gift-navigable {
    &:hover {
        background-color: #ec4185;
    }
}

.gift-purchased {
    background: #520a31;

    &:hover {
        background-color: #690e3f;
    }
}

.gift-title {
    margin: 8pt 0;
    font-size: 14pt;
}

.gift a {
    color: white;
    text-decoration: none;
}

.gift h3 a {
    text-decoration: none;
}

.gift-links-table {
    width: 100%;

    td:nth-child(1) {
        width: 40pt;
    }

    td {
        vertical-align: middle;
    }
}

.gift-link-icon a {
    text-align: center;
    font-size: 20pt;
    line-height: 0;
    text-decoration: none;
    font-weight: bold;
    color: white;
}

.gift-link-title {
    font-size: 12pt;
}

.gift-link-url {
    display: inline-block;
    font-size: 9pt;
    line-height: 1.5em;
    height: 1.5em;
    overflow: hidden;
}

@media only screen and (min-width: $siteWidth) {

    .gift {
        padding: 12px;
        margin-bottom: 12pt;
        border-width: 2pt;
        border-left-width: 12pt;
    }

    .gift-title {
        font-size: 18pt;
        margin-top: 10pt;
        margin-bottom: 10pt;
    }

    .gift-link-title {
        font-size: unset;
    }

    .gift-link-url {
        font-size: unset;
        line-height: unset;
        height: unset;
        overflow: unset;
    }
}