@import '../../../styles/_sizes.scss';

.top-bar-banner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    margin: auto;
    width: 100%;
    height: 80pt;
    object-fit: cover;
}

.top-bar-contents {
    max-width: $siteWidth;
    margin: 0 3pt;
    margin-bottom: 8pt;

    img {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 8pt;
        margin-top: 5pt;
    }
}

@media only screen and (min-width: $siteWidth) {

    .top-bar-contents {
        margin: 0 auto;
        margin-bottom: 8pt;
    }

}

.top-bar-contents-invert {
    filter: invert(1);
}

.top-bar-contents-right {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 8pt;
    padding: 4pt;
    float: right;
    text-align: right;

    span {
        font-weight: bold;
    }
}