@import '../../../styles/_colors.scss';

.minor-button {
  font-weight: bold;
  background: none;
  border: 1pt solid black;
  border-radius: 3pt;
  padding: 3pt;
  width: auto;
  font: unset;
  margin-right: 6pt;
  text-decoration: none;
  color: $black;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: rgb(243, 243, 243);
  }
}

.minor-button-white,
.minor-button-white a {
  border-color: $white;
  color: $white;
}