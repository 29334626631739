.info {
    display: flex;
    align-items: center;
    font-weight: bold;
    border: 1pt solid;
    border-radius: 8pt;
    padding: 3pt;
    border-color: black;
    background: white;
    color: black;
    padding-top: 5pt;
    padding-bottom: 5pt;
    min-height: 34pt;

    img {
        height: 30px;
        margin-right: 5pt;
        vertical-align: middle;
    }
}