.gift-link {
    border: 2pt solid #ddd;
    padding: 8pt;
    border-radius: 12pt;
    margin-bottom: 12pt;

    &:hover {
        background-color: rgb(241, 241, 241);
    }

    .gift-link-title {
        font-size: large;
    }
}