@import '../../../styles/_colors.scss';

.friend-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2pt solid #ddd;
    padding: 8pt;
    border-radius: 12pt;
    margin-bottom: 6pt;
}

.friend-toggle-switch {
    margin-left: auto;
    margin-top: 4pt;
    margin-right: 4pt;
    justify-content: flex-end;
}