@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    position: relative;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top-color: #000;
    border-radius: 50%;
    animation: spinner .6s linear infinite;
    margin-top: 10pt;
    margin-bottom: 20pt;
}