@import '../../../styles/_colors.scss';

.gift-link-title {
    font-size: 15pt;

    a {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.gift-link-url {
    font-size: 9pt;

    a {
        color: $black;
        opacity: 0.5;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}