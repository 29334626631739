.user-name {
    display: inline;

    img {
        display: absolute;
        margin-bottom: -6pt;
        width: 21pt;
        height: 21pt;
    }
}

.user-name-text {
    font-weight: bold;
}