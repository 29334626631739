@import '../../../styles/_colors.scss';
@import '../../../styles/_sizes.scss';

.list-summary-container {
    padding: 0 6pt;
    margin: 3pt 0;
    border-radius: 5pt;
    border: 2pt solid;
    border-top-color: #69c5ff;
    border-left-color: #69c5ff;
    border-bottom-color: #175175;
    border-right-color: #175175;
    background: #098fe3;
    color: white;
    font-size: 14pt;
}

.list-summary-container-friends,
.list-summary-container-archived {
    &:hover {
        background: #46a8e6;
    }
}

.list-summary-container-specific {
    border: 2pt solid;
    border-top-color: #fb9d67;
    border-left-color: #fb9d67;
    border-bottom-color: #b53f00;
    border-right-color: #b53f00;
    background: #ec5a0c;

    &:hover {
        background: #ff6d1f;
    }
}

.list-summary-container-private {
    background: #333;
    border-color: black;

    &:hover {
        background: #555;
    }
}

.list-summary-header {
    float: left;
    width: 65vw;
    line-height: 100%;
}

.list-summary-name {
    font-weight: bold;
}

.list-summary-count {
    margin-left: 5pt;
}

.list-summary-visibility-indicator {
    float: right;
    color: #eee;
    line-height: 0;

    .visibility-icon {
        height: 10px;
    }

    .visibility-indicator-text {
        font-size: 14px;
    }
}


.list-summary-end {
    clear: both;
}

@media only screen and (min-width: $siteWidth) {

    .list-summary-container {
        padding: 14pt;
        margin: 6pt 0;
    }

    .list-summary-header {
        width: auto;
        line-height: unset;
    }

    .list-summary-visibility-indicator {
        line-height: unset;

        .visibility-icon {
            height: 18px;
        }

        .visibility-indicator-text {
            font-size: unset;
        }
    }

}