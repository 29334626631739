.visibility-indicator .visibility-icon {
    height: 18px;
    vertical-align: middle;
}

.visibility-indicator-text {
    margin-left: 3pt;
    text-transform: capitalize;
    vertical-align: middle;
}

.visibility-indicator-friend {
    display: inline;
    border-radius: 2pt;
    border: 1pt solid silver;
    border-radius: 3pt;
    padding: 3pt;
    margin-left: 3pt;
    vertical-align: middle;
    font-weight: bold;
}