@import '../../../styles/_colors.scss';

.message-error {
    padding: 3pt;
    border: 1pt solid $red;
    border-left: 3pt solid $red;
    color: $red;
    border-radius: 10pt;
    padding: 10pt;
    margin-top: 10pt;
    margin-bottom: 10pt;
    font-size: smaller;
}

.message-error-title {
    font-weight: bold;
    margin: 0;
    margin-bottom: 4pt;
    padding: 0;
}

.message-error-message {
    font-size: small;
    margin: 0;
    padding: 0;
}