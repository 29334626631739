@import '../../../styles/_sizes.scss';

.link-box {
    padding: 16px;
    max-width: $siteWidth;
    margin: 0 auto;
    background-color: #f1f1f1;

    a {
      color: red;
    }
  }