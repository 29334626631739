@import '../../../styles/_colors.scss';

.action-button {
    width: auto;
    padding: 10px 18px;
    background-color: $lightGreen;
    color: $white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    font-size: 10pt;
    text-align: center;
    margin-right: 4pt;

    &:hover {
        opacity: 0.8;
    }
}

.action-button a {
    text-decoration: none;
    color: $white;
    display: inline-block;
    width: 100%;
}

.action-button-red {
    background-color: $red;
}
