@import '../../../styles/_colors.scss';

$padding: 8pt;
$hoverColor1: rgb(220, 218, 218);
$hoverColor2: rgb(245, 242, 242);

.friend-summary {
    background-color: rgb(229, 223, 223);
    border: 2pt solid #ddd;
    padding: $padding;
    border-radius: 12pt;
    margin-bottom: 12pt;
    overflow: hidden;
}

.friend-summary-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .down-chevron {
        margin-left: auto;
        font-size: 26pt;
    }

    .up-chevron {
        margin-left: auto;
        font-size: 26pt;
    }

    &:hover {
        background-color: $hoverColor1;
        margin: -$padding;
        padding: $padding;
    }
}

.friend-summary-content {
    display: none;

    .friend-summary-buttons {
        text-align: right;
    }
}

.friend-summary-expanded {
    background-color: transparent;

    .friend-summary-header {
        .down-chevron {
            display: none;
        }

        &:hover {
            background-color: $hoverColor2;
            margin: 0;
            padding: 0;
            border-radius: 6pt;
        }
    }

    .friend-summary-content {
        display: inherit;
    }
}