$height: 32pt;

.my-friend-request {

    height: $height;

    p {
        line-height: $height;
    }

    button {
        float: right;
        display: absolute;
        margin-top: -($height + $height / 2);
        right: 0;
        height: $height;
    }
}