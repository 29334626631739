.friend-request {
    border: 2pt solid #ddd;
    padding: 8pt;
    border-radius: 12pt;
    margin-bottom: 12pt;
}

.friend-request-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.friend-request-actions {
    margin-left: auto;
}