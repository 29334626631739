@import '../../../styles/_colors.scss';

.wide-button {
    border: 1px solid $lightGreen;
    background-color: $lightGreen;
    padding: 13px 0;
    color: $white;
    margin: 8px 0;
    cursor: pointer;
    width: 100%;
    font-size: 14pt;
    line-height: 100%;
    text-align: center;
    margin-right: 4pt;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        background-color: transparent;
        color: black;
        border-color: black;
        cursor: not-allowed;
    }
}

.wide-button a {
    text-decoration: none;
    color: white;
    display: inline-block;
    width: 100%;
}

.wide-button-red {
    border: 1px solid $red;
    background-color: $red;
}