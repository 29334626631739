@import '../../../styles/_colors.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &:before {
        border-radius: 50%;
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $white;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

input:checked+.slider {
    background-color: $blue;
}

input:focus+.slider {
    box-shadow: 0 0 1px $blue;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.switch-label {
    position: absolute;
    font-weight: bold;
    margin-top: 5pt;
    margin-left: 5pt;
}