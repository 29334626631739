.dialog-background {
    z-index: 1;
    backdrop-filter: blur(3px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.dialog {
    z-index: 2;
    position: fixed;
    left: 50%;
    top: 50%;
    max-height: 95vh;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    padding: 20pt;
    border-radius: 15pt;
    background-color: white;
    border: 3pt solid black;
    color: black;

    h1 {
        font-weight: bold;
        padding: 0 0 10pt 20pt;
        margin: 0 -20pt 20pt -20pt;
        border-bottom: 3pt solid black;
    }
}