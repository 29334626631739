.expanded-user {
    display: flex;
    align-items: center;
    gap: 2pt;
}

.expanded-user-icon {
    line-height: 0;

    img {
        width: 50px;
    }
}

.expanded-user-name {
    font-weight: bold;
}

.expanded-user-full-name::before {
    content: ' - ';
}