@import '../../../styles/_sizes.scss';

.container {
  max-width: $siteWidth;
  margin: 0 auto;
  padding: 0 6pt;
}

@media only screen and (min-width: $siteWidth) {

  .container {
    padding: 0;
  }

}