:root {
  --tab-text-color: #57606f;
  --tab-text-color--active: var(--site-text-color, #222);
  --tab-text-color--highlight: var(--site-link-color, #07c);
  --tab-border-color: #ddd;
}

.tab-nav {
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.tab-nav::-webkit-scrollbar {
  display: none;
}

.tab-list {
  width: fit-content;
  /* Let the tab-list shrink to fit its content */
  min-width: 100%;
  color: var(--tab-text-color);
  display: flex;
  gap: 0.5em;
  border-bottom: 2px solid var(--tab-border-color);
  margin: 0;
  padding: 0;
}

.tab-list li {
  display: block;
  margin-bottom: -2px;
}

.tab-btn {
  font: inherit;
  padding: 0.75em 1em;
  background-color: transparent;
  border: 2px solid var(--tab-border-color);
  border-width: 0 0 2px;
  cursor: pointer;
  color: inherit;
  white-space: nowrap;

  &:hover {
    background-color: rgb(235, 235, 235);
  }
}

.tab-btn:not(.tab-btn--active):hover {
  color: var(--site-text-color);
}

.tab-btn--active {
  color: var(--tab-text-color--highlight);
  border-color: var(--tab-text-color--highlight);
}

.tab-panel {
  margin-top: 4pt;
}